import { useState, useEffect } from 'react';
import { RESTAURANT_BACKEND_HOST } from './RestaurantBackend';

interface SocketDetails {
  type: 'server' | 'admin';
  client_host: string;
  client_port: number;
  connected_at: string;
  headers: Record<string, string>;
  server_id?: string;
}

interface SocketStatus {
  server_sockets: Record<string, string>;
  admin_sockets: string[];
  server_watchers: Record<string, string[]>;
  admin_watching: Record<string, string>;
  active_servers: string[];
  servers: Record<string, any>;
  connections: Record<string, SocketDetails>;
}

const SocketStatus = () => {
  const [status, setStatus] = useState<SocketStatus | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(`https://${RESTAURANT_BACKEND_HOST}/socket-status`);
        if (!response.ok) {
          throw new Error('Failed to fetch socket status');
        }
        const data = await response.json();
        setStatus(data);
        setError(null);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 5000); // Refresh every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const formatConnection = (socketId: string) => {
    if (!status?.connections[socketId]) return null;
    const details = status.connections[socketId];
    return `${details.client_host}:${details.client_port} (connected ${new Date(details.connected_at).toLocaleString()})`;
  };

  if (error) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          Error: {error}
        </div>
      </div>
    );
  }

  if (!status) {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="animate-pulse">Loading socket status...</div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Socket Manager Status</h2>

        {/* Active Servers */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Active Servers ({status.active_servers.length})</h3>
          <div className="bg-gray-50 rounded-lg p-4">
            {status.active_servers.length > 0 ? (
              <ul className="list-disc list-inside">
                {status.active_servers.map((serverId) => (
                  <li key={serverId} className="text-gray-700">
                    {status.servers[serverId]?.name || serverId}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No active servers</p>
            )}
          </div>
        </div>

        {/* Server Connections */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Server Connections</h3>
          <div className="bg-gray-50 rounded-lg p-4">
            {Object.entries(status.server_sockets).map(([serverId, socketId]) => (
              <div key={serverId} className="mb-2">
                <div className="font-medium">{status.servers[serverId]?.name || serverId}</div>
                <div className="text-gray-600 text-sm ml-4">
                  {formatConnection(socketId)}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Admin Connections */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Admin Connections ({status.admin_sockets.length})</h3>
          <div className="bg-gray-50 rounded-lg p-4">
            {status.admin_sockets.map((socketId, index) => (
              <div key={index} className="mb-2">
                <div className="text-gray-600">
                  {formatConnection(socketId)}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Watching Relationships */}
        <div>
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Watching Relationships</h3>
          <div className="bg-gray-50 rounded-lg p-4">
            {Object.entries(status.server_watchers).map(([serverId, watchers]) => (
              <div key={serverId} className="mb-4">
                <div className="font-medium mb-1">
                  {status.servers[serverId]?.name || serverId}
                </div>
                <div className="pl-4">
                  {watchers.map((socketId, index) => (
                    <div key={index} className="text-gray-600 text-sm">
                      {formatConnection(socketId)}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocketStatus; 
import { useState, useEffect, useRef } from 'react';
import { FaCheckCircle, FaTimesCircle, FaRegSquare, FaSpinner } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { RESTAURANT_BACKEND_HOST } from './RestaurantBackend';
import type { Task } from './AudioStreamer';
import { Summary } from '../../types/Summary';
import ReactMarkdown from 'react-markdown';

interface ServerInfo {
  id: string;
  name: string;
  role: string;
}

interface EvaluationResponse {
  tasks: Task[];
}

interface TranscriptSegment {
  speaker: number;
  text: string;
}

interface TranscriptSession {
  id: number;
  notification?: {
    text: string;
    positive: boolean;
    orderedItems?: string[];
  };
  transcriptParts: TranscriptSegment[];
  interimTranscript: string;
}

const RestaurantAdmin = () => {
  const [activeServers, setActiveServers] = useState<ServerInfo[]>([]);
  const [selectedServer, setSelectedServer] = useState<string | null>(null);
  const [sessions, setSessions] = useState<TranscriptSession[]>([
    { id: 0, transcriptParts: [], interimTranscript: '' }
  ]);
  const [evaluation, setEvaluation] = useState<Task[]>([]);
  const [isFinalEvaluation, setIsFinalEvaluation] = useState(false);
  const [summary, setSummary] = useState<Summary | null>(null);
  const [detailedSummary, setDetailedSummary] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const websocketRef = useRef<WebSocket | null>(null);

  const websocketUrl = `wss://${RESTAURANT_BACKEND_HOST}/ws/admin`;

  const connectWebSocket = () => {
    const ws = new WebSocket(websocketUrl);
    websocketRef.current = ws;

    ws.onopen = () => {
      console.log('Admin WebSocket connected');
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.debug('Received admin message:', data);

      switch (data.type) {
        case 'server_update':
          setActiveServers(data.data.active_servers);
          setSelectedServer(currentSelectedServer => {
            let newSelectedServer = currentSelectedServer;
            if (currentSelectedServer && !data.data.active_servers.some((server: ServerInfo) => server.id === currentSelectedServer)) {
              setEvaluation([]);
              setIsFinalEvaluation(false);
              setSummary(null);
              setIsProcessing(false);
              newSelectedServer = null;
              clearAllSessions();
            }
            if (newSelectedServer === null && data.data.active_servers.length === 1) {
              const serverId = data.data.active_servers[0].id;
              if (ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({
                  type: 'start_watching',
                  server_id: serverId
                }));
              }
              newSelectedServer = serverId;
            }
            return newSelectedServer;
          });
          break;
        case 'transcript_interim':
          if (typeof data.text === 'string') {
            setSessions(prevSessions => {
              const lastSession = prevSessions[prevSessions.length - 1];
              return [
                ...prevSessions.slice(0, -1),
                { ...lastSession, interimTranscript: data.text }
              ];
            });
          }
          break;
        case 'transcript_final':
          if (data.segments) {
            setSessions(prevSessions => {
              const lastSession = prevSessions[prevSessions.length - 1];
              const existingSegments = lastSession.transcriptParts;
              const combinedSegments = [...existingSegments, ...data.segments];

              return [
                ...prevSessions.slice(0, -1),
                {
                  ...lastSession,
                  transcriptParts: mergeConsecutiveSpeakers(combinedSegments),
                  interimTranscript: ''
                }
              ];
            });
          }
          break;
        case 'notification':
          setSessions(prevSessions => [
            ...prevSessions,
            {
              id: prevSessions.length,
              notification: {
                text: data.text,
                positive: data.positive,
                orderedItems: data.orderedItems
              },
              transcriptParts: [],
              interimTranscript: ''
            }
          ]);
          break;
        case 'evaluation':
          if (Array.isArray(data.data.tasks)) {
            setEvaluation(data.data.tasks);
          }
          break;
        case 'evaluation_final':
          if (Array.isArray(data.data.tasks)) {
            setIsFinalEvaluation(true);
            setEvaluation(data.data.tasks);
            setSummary(data.summary || null);
            setDetailedSummary(data.detailedSummary || null);
            setIsProcessing(false);
          }
          break;
      }
    };

    ws.onclose = () => {
      console.log('Admin WebSocket disconnected. Reconnecting...');
      setTimeout(connectWebSocket, 1000);
    };
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, []);

  const clearAllSessions = () => {
    setSessions([{ id: 0, transcriptParts: [], interimTranscript: '' }]);
  };

  const handleServerSelect = (serverId: string) => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      if (selectedServer === serverId) {
        websocketRef.current.send(JSON.stringify({
          type: 'stop_watching',
          server_id: serverId
        }));
        console.debug('clearing2 selectedServer');
        setSelectedServer(null);
        setEvaluation([]);
        setIsFinalEvaluation(false);
        setSummary(null);
        setIsProcessing(false);
        clearAllSessions();
      } else {
        clearAllSessions();
        setEvaluation([]);
        setIsFinalEvaluation(false);
        setSummary(null);

        if (selectedServer) {
          websocketRef.current.send(JSON.stringify({
            type: 'stop_watching',
            server_id: selectedServer
          }));
        }

        websocketRef.current.send(JSON.stringify({
          type: 'start_watching',
          server_id: serverId
        }));
        console.debug('setting2 selectedServer', serverId);
        setSelectedServer(serverId);
      }
    }
  };

  const mergeConsecutiveSpeakers = (segments: TranscriptSegment[]): TranscriptSegment[] => {
    return segments.reduce((acc: TranscriptSegment[], curr) => {
      const lastSegment = acc[acc.length - 1];

      if (lastSegment && lastSegment.speaker === curr.speaker) {
        // Combine text of same speaker
        lastSegment.text += ' ' + curr.text;
        return acc;
      } else {
        // Different speaker or first segment
        return [...acc, { ...curr }];
      }
    }, []);
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Restaurant Admin
        </h2>

        {/* Server Selection */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">Active Servers</h3>
          <div className="flex gap-2">
            {activeServers.map(server => (
              <button
                key={server.id}
                onClick={() => handleServerSelect(server.id)}
                className={`px-4 py-2 rounded-lg ${selectedServer === server.id
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
                  }`}
              >
                {server.name}
              </button>
            ))}
            {activeServers.length === 0 && (
              <p className="text-gray-500">No active servers</p>
            )}
          </div>
        </div>

        {selectedServer && (
          <>
            {/* Live Events */}
            {sessions.map((session, index) => (
              <div key={session.id} className="mb-6">
                {index === 0 ? (
                  <h3 className="text-lg font-semibold text-gray-700 mb-2">
                    Live Events
                  </h3>
                ) : session.notification && (
                  <>
                    <div className={`mb-2 p-2 rounded ${session.notification.positive
                      ? 'bg-green-100 text-green-800'
                      : 'bg-yellow-100 text-yellow-800'
                      }`}>
                      <h3 className="text-lg font-semibold mb-1">Notification Sent:</h3>
                      <p>{session.notification.text}</p>
                    </div>
                    {session.notification.orderedItems && session.notification.orderedItems.length > 0 && (
                      <div className="mb-2">
                        <h4 className="font-semibold">Ordered Items:</h4>
                        <ul className="list-disc list-inside">
                          {session.notification.orderedItems.map((item, idx) => (
                            <li key={idx}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </>
                )}

                <div className="bg-gray-50 rounded-lg p-4 min-h-[100px] max-h-[200px] overflow-y-auto">
                  {session.transcriptParts.map((segment, index) => (
                    <div key={index} className="mb-2">
                      <span className="font-semibold text-gray-600">[Speaker{segment.speaker}]: </span>
                      {segment.text}
                      {index === session.transcriptParts.length - 1 && session.interimTranscript && (
                        <span className="text-gray-500 italic"> {session.interimTranscript}</span>
                      )}
                    </div>
                  ))}
                  {session.transcriptParts.length === 0 && session.interimTranscript && (
                    <div className="text-gray-500 italic">{session.interimTranscript}</div>
                  )}
                  {session.transcriptParts.length === 0 && !session.interimTranscript && (
                    'Waiting for transcript...'
                  )}
                </div>
              </div>
            ))}

            {/* Task Checklist */}
            <div>
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Required Tasks
                {isProcessing && (
                  <FaSpinner className="inline ml-2 animate-spin text-blue-500" />
                )}
              </h3>
              <div className="bg-gray-50 rounded-lg p-4">
                {evaluation.length === 0 ? (
                  <p className="text-gray-500">Waiting for evaluation...</p>
                ) : (
                  <ul className="space-y-3">
                    {evaluation.map((task, index) => (
                      <li
                        key={index}
                        className="flex items-center gap-3 p-2 bg-white rounded shadow-sm"
                      >
                        {task.done ? (
                          <FaCheckCircle className="w-5 h-5 text-green-500 flex-shrink-0" />
                        ) : isFinalEvaluation ? (
                          <FaTimesCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
                        ) : (
                          <FaRegSquare className="w-5 h-5 text-gray-400 flex-shrink-0" />
                        )}
                        <span className="flex-grow">{task.task}</span>
                        <Tooltip id={`tooltip-${index}`} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            {/* Performance Summary */}
            {isFinalEvaluation && summary && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold text-gray-700 mb-2">
                  Shift Performance Summary
                </h3>
                <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                  {summary.well.length > 0 && (
                    <div>
                      <h4 className="font-medium text-green-600 mb-2">Done Well:</h4>
                      <ul className="list-disc list-inside space-y-1">
                        {summary.well.map((item, index) => (
                          <li key={index} className="text-gray-700">{item}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {summary.better.length > 0 && (
                    <div>
                      <h4 className="font-medium text-blue-600 mb-2">Could Be Better:</h4>
                      <ul className="list-disc list-inside space-y-1">
                        {summary.better.map((item, index) => (
                          <li key={index} className="text-gray-700">{item}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Detailed Shift Analysis */}
            {isFinalEvaluation && detailedSummary && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold text-gray-700 mb-2">
                  Detailed Shift Analysis
                </h3>
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="prose prose-sm max-w-none text-gray-700">
                    <ReactMarkdown>{detailedSummary}</ReactMarkdown>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RestaurantAdmin; 
import React, { useState, useEffect } from 'react';
import { BiTime } from 'react-icons/bi';
import { FaUsers, FaDollarSign, FaUtensils } from 'react-icons/fa';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import ReactMarkdown from 'react-markdown';
import ManagerRecommendations from './ManagerRecommendations';
import MetricsDashboard from './MetricsDashboard';

// Type definitions
interface Table {
  id: number;
  seats: number;
  currentGuests: number;
  serverId: number | null;
  status: 'empty' | 'occupied' | 'reserved';
  seatedTime?: number; // Time when guests were seated (in hours, e.g., 12.5 for 12:30)
  expectedDepartureTime?: number; // Expected departure time in hours
}

interface Staff {
  id: number;
  name: string;
  primaryRole: StaffRole;
  secondaryRoles: StaffRole[];
  shift: 'single' | 'double';
  startTime: number;
  endTime: number; // Add end time
  breakTime?: number;
  status?: 'active' | 'planned' | 'late'; // Add status
}

interface Reservation {
  id: number;
  time: number;
  partySize: number;
  name: string;
  tableId: number;
}

interface Metrics {
  currentRevenue: number;
  laborCost: number;
  occupancyRate: number;
  reservationsCount: number;
}

interface NewReservation {
  time: number;
  partySize: number;
  name: string;
  tableId: number | null;
}

// Add StaffRole type
type StaffRole =
  | 'server'
  | 'server high performer'
  | 'runner'
  | 'hostess'
  | 'busser'
  | 'cook'
  | 'dishwasher'
  | 'bartender';

// Update INITIAL_TABLES with current timing
const INITIAL_TABLES: Table[] = [
  { id: 1, seats: 4, currentGuests: 0, serverId: 1, status: 'empty' },
  {
    id: 2,
    seats: 2,
    currentGuests: 2,
    serverId: 1,
    status: 'occupied',
    seatedTime: 12.0, // Moved back to 12:00 PM
    expectedDepartureTime: 13.25 // Expected to leave at 1:15 PM
  },
  {
    id: 3,
    seats: 6,
    currentGuests: 5,
    serverId: 2,
    status: 'occupied',
    seatedTime: 13.25, // Seated at 1:15 PM
    expectedDepartureTime: 14.5 // Expected to leave at 2:30 PM
  },
  {
    id: 4,
    seats: 4,
    currentGuests: 0,
    serverId: 4,
    status: 'reserved' // Reserved for later
  },
  {
    id: 5,
    seats: 4,
    currentGuests: 3,
    serverId: 4,
    status: 'occupied',
    seatedTime: 13.75, // Seated at 1:45 PM
    expectedDepartureTime: 15 // Expected to leave at 3:00 PM
  },
  {
    id: 6,
    seats: 2,
    currentGuests: 2,
    serverId: 2,
    status: 'occupied',
    seatedTime: 13.5,
    expectedDepartureTime: 14.75
  },
];

// Update INITIAL_STAFF with end times
const INITIAL_STAFF: Staff[] = [
  {
    id: 1,
    name: 'Alice Johnson',
    primaryRole: 'server high performer',
    secondaryRoles: ['hostess', 'runner'],
    shift: 'double',
    startTime: 10,
    endTime: 22, // 10 PM
    breakTime: 12.5,
    status: 'active'
  },
  {
    id: 2,
    name: 'Bob Smith',
    primaryRole: 'server',
    secondaryRoles: ['runner', 'busser'],
    shift: 'single',
    startTime: 11,
    endTime: 22,
    breakTime: 13.5,
    status: 'active'
  },
  {
    id: 3,
    name: 'Emily Taylor',
    primaryRole: 'server',
    secondaryRoles: ['hostess'],
    shift: 'single',
    startTime: 13,
    endTime: 22,
    status: 'active'
  },
  {
    id: 4,
    name: 'Diana Wilson',
    primaryRole: 'bartender',
    secondaryRoles: ['server'],
    shift: 'single',
    startTime: 13,
    endTime: 22,
    status: 'active'
  },
  {
    id: 5,
    name: 'Charlie Davis',
    primaryRole: 'runner',
    secondaryRoles: ['busser'],
    shift: 'single',
    startTime: 11,
    endTime: 22,
    status: 'active'
  },
  {
    id: 6,
    name: 'Erik Martinez',
    primaryRole: 'cook',
    secondaryRoles: ['dishwasher'],
    shift: 'double',
    startTime: 9,
    endTime: 22,
    breakTime: 11.5,
    status: 'active'
  }
];

const INITIAL_RESERVATIONS: Reservation[] = [
  { id: 1, time: 14, partySize: 4, name: 'Smith', tableId: 4 },
  { id: 2, time: 18, partySize: 2, name: 'Johnson', tableId: 6 },
];

// Business rules with type definitions
const BusinessRules = {
  shouldAddServer: (metrics: Metrics): boolean => {
    const { currentRevenue, laborCost, occupancyRate, reservationsCount } = metrics;
    return (
      occupancyRate > 0.8 ||
      (reservationsCount > 3 && laborCost < 22) ||
      (currentRevenue / INITIAL_TABLES.length > 100 && laborCost < 25)
    );
  },

  canTakeBreak: (server: Staff, currentTime: number): boolean => {
    const hoursWorked = currentTime - server.startTime;
    return hoursWorked >= 2 && hoursWorked <= 6 && server.shift === 'double';
  }
};

// Add helper function to format time
const formatTime = (time?: number): string => {
  if (!time) return '--:--';
  const hours = Math.floor(time);
  const minutes = Math.round((time % 1) * 60);
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

// Add PLANNED_STAFF
const PLANNED_STAFF: Staff[] = [
  {
    id: 101,
    name: 'Mike Thompson',
    primaryRole: 'server',
    secondaryRoles: ['runner'],
    shift: 'single',
    startTime: 13.5, // Should have started at 1:30 PM
    endTime: 22,
    status: 'late'
  },
  {
    id: 102,
    name: 'Sarah Chen',
    primaryRole: 'hostess',
    secondaryRoles: ['runner'],
    shift: 'single',
    startTime: 16, // Starts at 4 PM
    endTime: 23,
    status: 'planned'
  },
  {
    id: 103,
    name: 'James Wilson',
    primaryRole: 'cook',
    secondaryRoles: [],
    shift: 'single',
    startTime: 16, // Starts at 4 PM
    endTime: 23,
    status: 'planned'
  }
];

// Add helper function to calculate time until start
const getTimeUntilStart = (startTime: number, currentTime: number): string => {
  const hoursUntil = startTime - currentTime;
  if (hoursUntil < 0) return 'Late';
  const hours = Math.floor(hoursUntil);
  const minutes = Math.round((hoursUntil % 1) * 60);
  if (hours === 0) return `${minutes} minutes`;
  return `${hours}h ${minutes}m`;
};

// Add helper function to determine current shift
const getCurrentShift = (time: number): string => {
  if (time >= 6 && time < 11) return 'Breakfast';
  if (time >= 11 && time < 16) return 'Lunch';
  if (time >= 16 && time < 23) return 'Dinner';
  return 'Closed';
};

// Add helper function to format date
const formatDate = (): string => {
  const date = new Date();
  return date.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

// Update interface for structured recommendations
interface Recommendation {
  id: number;
  message: string;
  effect: string;
  effectColor?: 'green' | 'default';
  reasoning: string;
  urgency: 'urgent' | 'normal';
  actions: ('done' | 'call' | 'text' | 'text coupon')[];
}

// Update recommendations structure
const getRecommendations = (): Recommendation[] => {
  return [
    {
      id: 1,
      message: "Mike Thompson is 30 minutes late for his shift",
      effect: "Server shortage",
      reasoning: `- Pre-dinner rush starts at 18:15 p.m.
- Current server-to-table ratio is 1:4 (Should be 1:3 for dinner service)
- Mike's absence puts dinner service preparation at risk.

**Note**: Mike was late 3 times in the last 2 weeks.`,
      urgency: 'urgent',
      actions: ['call', 'text', 'done']
    },
    {
      id: 2,
      message: "End Bob Smith's shift early",
      effect: "↓ 4% labor cost",
      effectColor: 'green',
      reasoning: `- Alice Johnson, high performing server, and Emily Taylor can cover her shift
- No other servers need to take breaks for rest of shift

- 18% less busy than usual
    - Video AI shows fewer customers
    - Reservations are slightly less than normal
    - Post-lunch lull period (2-4pm).
    - Only 2 of 7 tables active in his section.
    - Weather is rainy

- Current labor cost is tracking towards 26% vs target of 22%.
- Anticipated busyness also 20% lower than usual`,
      urgency: 'urgent',
      actions: ['call', 'text', 'done']
    },
    {
      id: 5,
      message: "Consider ending Diana Wilson's shift early",
      effect: "↓ 2% labor cost",
      effectColor: 'green',
      reasoning: `- Bar orders are 70% below average for this shift
- Happy hour doesn't start until 4pm.
    - Manager, Smith, can operate as backup bartender until then.
- 18% less busy than usual
    - Video AI shows fewer customers
    - Reservations are slightly less than normal
    - Post-lunch lull period (2-4pm).
    - Only 2 of 7 tables active in his section.
    - Weather is rainy
- Current labor cost is tracking towards 26% vs target of 22%.
- Busyness of dinner shift expected to be 20% lower than usual
    - Current slowness will likely transfer to traffic tonight
    - Fewer reservations on Yelp than same day last year`,
      urgency: 'urgent',
      actions: ['call', 'text', 'done']
    },
    {
      id: 3,
      message: "Offer Table 2 a 50% off drink discount",
      effect: "↑ 0.5% revenue",
      effectColor: 'green',
      reasoning: `- Long-stay guests during slow period (2-4pm)
- Check if they need drinks.
- Each drink order adds $8-15 revenue with 40-60% margin (even with discount).
- Server should suggest specialty afternoon cocktails.`,
      urgency: 'normal',
      actions: ['text coupon', 'done']
    },
    {
      id: 4,
      message: "Charlie Davis should take break in next 30 minutes",
      effect: "Break required",
      reasoning: `- Working since 11am without break.
- Legal requirement for 30-min break within first 5 hours (by 16:00).
- Current slow period optimal for break before dinner prep starts.
- 18% less busy than usual
    - Video AI shows fewer customers
    - Reservations are slightly less than normal
    - Post-lunch lull period (2-4pm).
    - Only 2 of 7 tables active in his section.
    - Weather is rainy`,
      urgency: 'normal',
      actions: ['call', 'text', 'done']
    },
    {
      id: 6,
      message: "Ask Emily to clean front windows",
      effect: "Improved efficiency",
      reasoning: `- Emily has no active tables.
- Windows haven't been cleaned today.
- Task takes ~20 minutes and improves curb appeal for dinner service.
- 18% less busy than usual
    - Video AI shows fewer customers
    - Reservations are slightly less than normal
    - Post-lunch lull period (2-4pm).
    - Only 2 of 7 tables active in his section.
    - Weather is rainy

**Note:** Emily Taylor has requested a double shift for tomorrow (working lunch and dinner)`,
      urgency: 'normal',
      actions: ['call', 'text', 'done']
    },
  ];
};

const RestaurantDashboard: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<number>(12);
  const [tables, setTables] = useState<Table[]>(INITIAL_TABLES);
  const [currentStaff] = useState<Staff[]>(INITIAL_STAFF);
  const [plannedStaff] = useState<Staff[]>(PLANNED_STAFF);
  const [reservations, setReservations] = useState<Reservation[]>(INITIAL_RESERVATIONS);
  // Update metrics state with consistent targets and current values
  const [metrics, setMetrics] = useState<Metrics>({
    currentRevenue: 3200,          // Current: $3,200 | Target: $2,800 (↑ 14.3%)
    laborCost: 1312,               // Current: $1,312 | Target: $560 (↑ 134%) [20% of target revenue]
    occupancyRate: 0.7,
    reservationsCount: INITIAL_RESERVATIONS.length,
  });
  const [newReservation, setNewReservation] = useState<NewReservation>({
    time: currentTime,
    partySize: 2,
    name: '',
    tableId: null
  });
  // Add state to track handled recommendations
  const [handledRecommendations, setHandledRecommendations] = useState<{ [key: number]: 'done' | 'ignored' }>({});

  // Add state for section collapse
  const [collapsedSections, setCollapsedSections] = useState({
    currentStaff: true,
    plannedStaff: true,
    tables: true,
    reservations: true
  });

  const toggleSection = (section: keyof typeof collapsedSections) => {
    setCollapsedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const updateMetrics = (): void => {
    const occupiedTables = tables.filter(t => t.status === 'occupied').length;
    setMetrics(prev => ({
      ...prev,
      occupancyRate: occupiedTables / tables.length,
      reservationsCount: reservations.length
    }));
  };

  const handleAddWalkIn = (tableId: number, partySize: number): void => {
    setTables(tables.map(table =>
      table.id === tableId ? {
        ...table,
        currentGuests: partySize,
        status: 'occupied',
        seatedTime: 12, // Current fixed time
        expectedDepartureTime: 13.5 // Default 1.5 hours from seating
      } : table
    ));
    updateMetrics();
  };

  const handleAddReservation = (): void => {
    if (!newReservation.name || !newReservation.tableId) return;

    const newId = Math.max(...reservations.map(r => r.id), 0) + 1;
    setReservations([...reservations, { ...newReservation, id: newId } as Reservation]);
    setNewReservation({
      time: currentTime,
      partySize: 2,
      name: '',
      tableId: null
    });
    updateMetrics();
  };

  const handleCancelReservation = (id: number): void => {
    setReservations(reservations.filter(r => r.id !== id));
    updateMetrics();
  };

  const getServerName = (serverId: number | null): string => {
    const server = currentStaff.find(s => s.id === serverId);
    return server ? server.name : 'Unassigned';
  };

  const getStaffStatusDisplay = (employee: Staff, currentTime: number) => {
    if (employee.status === 'late') {
      const minutesLate = Math.round((currentTime - employee.startTime) * 60);
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
          {minutesLate} minutes late - Call immediately
        </span>
      );
    }
    if (employee.status === 'planned') {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
          Starts at {formatTime(employee.startTime)}
        </span>
      );
    }
    return null;
  };

  return (
    <div className="p-4 max-w-7xl mx-auto space-y-4">
      {/* Metrics Cards */}
      <MetricsDashboard />

      {/* Recommendations */}
      <ManagerRecommendations />


      {/* Current Staff Section */}
      <div className="bg-white rounded-lg shadow p-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleSection('currentStaff')}
        >
          <h3 className="text-lg font-semibold mb-4">Current Staff</h3>
          {collapsedSections.currentStaff ?
            <IoChevronDownOutline className="h-5 w-5" /> :
            <IoChevronUpOutline className="h-5 w-5" />
          }
        </div>

        {!collapsedSections.currentStaff && (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Name</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Primary Role</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Secondary Roles</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Shift Type</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Time</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Hours Working</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Break Status</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Upsell %</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentStaff.map((employee) => (
                  <tr key={employee.id} className="hover:bg-gray-50">
                    <td className="px-4 py-3">{employee.name}</td>
                    <td className="px-4 py-3">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {employee.primaryRole}
                      </span>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex flex-wrap gap-1">
                        {employee.secondaryRoles.map((role, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            {role}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${employee.shift === 'double'
                        ? 'bg-purple-100 text-purple-800'
                        : 'bg-green-100 text-green-800'
                        }`}>
                        {employee.shift}
                      </span>
                    </td>
                    <td className="px-4 py-3">
                      {formatTime(employee.startTime)} - {formatTime(employee.endTime)}
                    </td>
                    <td className="px-4 py-3">
                      {14 - employee.startTime} hours
                    </td>
                    <td className="px-4 py-3">
                      {employee.breakTime ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Break taken at {formatTime(employee.breakTime)}
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                          {employee.name === 'Charlie Davis' ? 'Break not taken (earliest: 13:00)' :
                            employee.name === 'Diana Wilson' ? 'Break not taken (earliest: 15:00)' :
                              'Break not taken'}
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-3">
                      {(employee.primaryRole === 'server' || employee.primaryRole === 'server high performer') ? (
                        <span className="text-green-600 font-medium">
                          {employee.primaryRole === 'server high performer' ? '24%' : '18%'}
                        </span>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Planned Staff Section */}
      <div className="bg-white rounded-lg shadow p-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleSection('plannedStaff')}
        >
          <h3 className="text-lg font-semibold mb-4">Planned Staff</h3>
          {collapsedSections.plannedStaff ?
            <IoChevronDownOutline className="h-5 w-5" /> :
            <IoChevronUpOutline className="h-5 w-5" />
          }
        </div>

        {!collapsedSections.plannedStaff && (
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Name</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Primary Role</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Secondary Roles</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Shift Type</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Time</th>
                  <th className="px-4 py-2 text-left text-sm font-semibold text-gray-600">Starts In</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {plannedStaff.map((employee) => (
                  <tr key={employee.id} className="hover:bg-gray-50">
                    <td className="px-4 py-3">{employee.name}</td>
                    <td className="px-4 py-3">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                        {employee.primaryRole}
                      </span>
                    </td>
                    <td className="px-4 py-3">
                      <div className="flex flex-wrap gap-1">
                        {employee.secondaryRoles.map((role, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            {role}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="px-4 py-3">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${employee.shift === 'double'
                        ? 'bg-purple-100 text-purple-800'
                        : 'bg-green-100 text-green-800'
                        }`}>
                        {employee.shift}
                      </span>
                    </td>
                    <td className="px-4 py-3">
                      {formatTime(employee.startTime)} - {formatTime(employee.endTime)}
                    </td>
                    <td className="px-4 py-3">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${employee.status === 'late'
                        ? 'bg-red-100 text-red-800'
                        : 'bg-blue-100 text-blue-800'
                        }`}>
                        {getTimeUntilStart(employee.startTime, 14)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Table Management */}
      <div className="bg-white rounded-lg shadow p-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleSection('tables')}
        >
          <h3 className="text-lg font-semibold mb-4">Tables</h3>
          {collapsedSections.tables ?
            <IoChevronDownOutline className="h-5 w-5" /> :
            <IoChevronUpOutline className="h-5 w-5" />
          }
        </div>

        {!collapsedSections.tables && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {tables.map(table => (
              <div key={table.id} className="border rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="font-bold">Table {table.id}</h3>
                  <span className={`px-2 py-1 rounded text-sm ${table.status === 'occupied' ? 'bg-green-100 text-green-800' :
                    table.status === 'reserved' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                    {table.status}
                  </span>
                </div>
                <div className="space-y-2 text-sm">
                  <div>Capacity: {table.seats}</div>
                  <div>Current Guests: {table.currentGuests}</div>
                  <div>Server: {getServerName(table.serverId)}</div>

                  {/* Add timing information */}
                  {table.status === 'occupied' && (
                    <>
                      <div className="border-t pt-2 mt-2">
                        <div className="flex justify-between items-center text-gray-600">
                          <span>Seated:</span>
                          <span className="font-medium">{formatTime(table.seatedTime)}</span>
                        </div>
                        <div className="flex justify-between items-center text-gray-600">
                          <span>Expected Departure:</span>
                          <span className="font-medium">{formatTime(table.expectedDepartureTime)}</span>
                        </div>
                        {table.expectedDepartureTime && table.expectedDepartureTime < 14 && (
                          <div className="mt-1 text-amber-600 text-sm">
                            45 minutes over expected departure time
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Reservations Management */}
      <div className="bg-white rounded-lg shadow p-4">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleSection('reservations')}
        >
          <h3 className="text-lg font-semibold mb-4">Reservations</h3>
          {collapsedSections.reservations ?
            <IoChevronDownOutline className="h-5 w-5" /> :
            <IoChevronUpOutline className="h-5 w-5" />
          }
        </div>

        {!collapsedSections.reservations && (
          <div className="space-y-2">
            {reservations
              .sort((a, b) => a.time - b.time)
              .map(reservation => (
                <div key={reservation.id} className="flex justify-between items-center p-2 bg-gray-50 rounded">
                  <div>
                    <span className="font-medium">{reservation.name}</span>
                    <span className="ml-2 text-sm text-gray-600">
                      ({reservation.partySize} guests @ {Math.floor(reservation.time)}:
                      {(reservation.time % 1) * 60 === 0 ? '00' : '30'})                    </span>
                    <span className="ml-2 text-sm text-gray-600">
                      Table {reservation.tableId}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RestaurantDashboard;
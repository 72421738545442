import React from 'react';
import { Phone, MessageCircle, X, Info, Check, AlertTriangle } from 'lucide-react';

interface Recommendation {
  text: string;
  effect: string;
  info: string;
  img: string;
}

interface Recommendations {
  urgent: Recommendation[];
  laborCost: Recommendation[];
  revenue: Recommendation[];
  efficiency: Recommendation[];
}

interface AvatarProps {
  name: string;
}

interface RecommendationRowProps {
  rec: Recommendation;
}

interface SectionTitleProps {
  children: React.ReactNode;
}

const ManagerRecommendations: React.FC = () => {
  const recommendations: Recommendations = {
    urgent: [
      {
        text: '⚠️ Mike Thompson is 30 minutes late for his shift',
        effect: 'Server shortage',
        info: 'Expected arrival time was 13:30. No response to calls or messages. This is his second late arrival this week.',
        img: '/img/people/mt.png'
      },
      {
        text: '⏰ Charlie Davis should take break in next 30 minutes',
        effect: 'Break required',
        info: 'Charlie has been working for 5 hours without a break. Labor law requires break within next 30 minutes.',
        img: '/img/people/cd.png'
      }
    ],
    laborCost: [
      {
        text: '💰 End Bob Smith\'s shift early',
        effect: '↓ 4% labor cost',
        info: 'Current labor costs are trending 34% above target. Bob has only 2 active tables in his section and the lunch rush is over.',
        img: '/img/people/bs.png'
      },
      {
        text: '💰 Consider ending Diana Wilson\'s shift early',
        effect: '↓ 2% labor cost',
        info: 'Diana has completed her sidework and section is slow. Projected evening volume is lower than usual.',
        img: '/img/people/dw.png'
      }
    ],
    revenue: [
      {
        text: '🍷 Offer Table 2 a 50% off drink discount',
        effect: '↑ 0.5% revenue',
        info: 'Table has been waiting 10 minutes longer than quoted time. High-value regulars who typically spend $150+ per visit.',
        img: '/img/people/table2.png'
      }
    ],
    efficiency: [
      {
        text: '🧹 Ask Emily to clean front windows',
        effect: 'Improved efficiency',
        info: 'Emily has no active tables. Windows haven\'t been cleaned today. Task takes ~20 minutes and improves curb appeal for dinner service.',
        img: '/img/people/em.png'
      }
    ]
  };

  const Avatar: React.FC<AvatarProps> = ({ name }) => {
    return (
      <img
        src={name}
        alt={name}
        className="w-8 h-8 rounded-full object-cover"
      />
    );
  };

  const RecommendationRow: React.FC<RecommendationRowProps> = ({ rec }) => (
    <div className="flex items-center justify-between bg-gray-50 p-2 rounded">
      <div className="flex items-center space-x-2 flex-1">
        <div className="flex items-center">
          {rec.img && (
            <div className="mr-3">
              <Avatar name={rec.img} />
            </div>
          )}
          <span className="text-sm">{rec.text}</span>
        </div>
        <div className="group relative">
          <Info className="w-4 h-4 text-gray-400 cursor-help" />
          <div className="absolute left-0 bottom-full mb-2 hidden group-hover:block w-64 bg-gray-900 text-white p-2 rounded text-xs z-10">
            {rec.info}
            <div className="absolute left-0 bottom-0 transform translate-y-full">
              <div className="w-2 h-2 bg-gray-900 transform rotate-45 -translate-y-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center ml-4">
        <span className={`text-sm font-medium px-2 py-0.5 rounded-full mr-4 ${rec.effect.includes('labor') || rec.effect.includes('cost') ? 'text-emerald-700 bg-emerald-50' : rec.effect.includes('revenue') ? 'text-blue-700 bg-blue-50' : rec.effect.includes('Server shortage') ? 'text-red-700 bg-red-50' : rec.effect.includes('Break required') ? 'text-amber-700 bg-amber-50' : 'text-violet-700 bg-violet-50'}`}>{rec.effect}</span>
        <div className="flex gap-2">
          <button className="bg-blue-800 hover:bg-blue-900 text-white px-3 py-1.5 rounded-lg text-sm flex items-center">
            <Phone className="w-3.5 h-3.5 mr-1.5" strokeWidth={2.5} />
            Call
          </button>
          <button className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-1.5 rounded-lg text-sm flex items-center">
            <MessageCircle className="w-3.5 h-3.5 mr-1.5" strokeWidth={2.5} />
            Text
          </button>
          <button className="bg-teal-500 hover:bg-teal-600 text-white px-3 py-1.5 rounded-lg text-sm flex items-center">
            <Check className="w-3.5 h-3.5 mr-1.5" strokeWidth={2.5} />
            Done
          </button>
          <button className="text-blue-800 hover:bg-blue-50 p-1 rounded-lg">
            <X className="w-5 h-5" strokeWidth={2.5} />
          </button>
        </div>
      </div>
    </div>
  );

  const SectionTitle: React.FC<SectionTitleProps> = ({ children }) => (
    <h3 className="text-base font-semibold text-gray-700 mb-3 uppercase tracking-wide">{children}</h3>
  );

  return (
    <div className="p-3 bg-white">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Manager Recommendations</h2>

      {recommendations.urgent.length > 0 && (
        <div className="mb-6">
          <div className="bg-red-50 border-l-4 border-red-500 px-0 py-3 rounded">
            <div className="flex items-center mb-2 px-3">
              <AlertTriangle className="w-5 h-5 text-red-600 mr-2" />
              <h3 className="text-base font-semibold text-red-600 uppercase tracking-wide">Urgent Attention Required</h3>
            </div>
            <div className="space-y-2">
              {recommendations.urgent.map((rec, index) => (
                <RecommendationRow key={`urgent-${index}`} rec={rec} />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="mb-6">
        <SectionTitle>Labor Cost Reduction</SectionTitle>
        <div className="space-y-2">
          {recommendations.laborCost.map((rec, index) => (
            <RecommendationRow key={`labor-${index}`} rec={rec} />
          ))}
        </div>
      </div>

      <div className="mb-6">
        <SectionTitle>Revenue Improvement</SectionTitle>
        <div className="space-y-2">
          {recommendations.revenue.map((rec, index) => (
            <RecommendationRow key={`revenue-${index}`} rec={rec} />
          ))}
        </div>
      </div>

      <div className="mb-6">
        <SectionTitle>Efficiency</SectionTitle>
        <div className="space-y-2">
          {recommendations.efficiency.map((rec, index) => (
            <RecommendationRow key={`efficiency-${index}`} rec={rec} />
          ))}
        </div>
      </div>
    </div>

  );
};

export default ManagerRecommendations;
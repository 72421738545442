import React from 'react';

const GradientBespoke: React.FC = () => {
  return (
    <>
      <h1 className="text-3xl sm:text-3xl font-bold">
        <span className="text-transparent bg-clip-text bg-gradient-to-r from-slate-700 to-slate-900">
          Bespoke
        </span>
      </h1>
    </>);
};

export default GradientBespoke;
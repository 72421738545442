import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FaGripVertical, FaTrash, FaPlus } from 'react-icons/fa';
import { RESTAURANT_BACKEND_HOST } from './RestaurantBackend';

const URL = `https://${RESTAURANT_BACKEND_HOST}/criteria`;

const RestaurantCriteria: React.FC = () => {
  const [criteria, setCriteria] = useState<string[]>([]);
  const [newCriterion, setNewCriterion] = useState('');
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCriteria();
  }, []);

  const fetchCriteria = async () => {
    try {
      const response = await fetch(URL);
      const data = await response.json();
      setCriteria(data.criteria);
      setIsLoading(false);
    } catch (err) {
      setError('Failed to load criteria');
      setIsLoading(false);
    }
  };

  const handleDragEnd = async (result: any) => {
    if (!result.destination) return;

    const items = Array.from(criteria);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCriteria(items);

    try {
      await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ criteria: items }),
      });
    } catch (err) {
      setError('Failed to save criteria order');
    }
  };

  const handleDelete = async (index: number) => {
    const newCriteria = criteria.filter((_, i) => i !== index);
    setCriteria(newCriteria);

    try {
      await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ criteria: newCriteria }),
      });
    } catch (err) {
      setError('Failed to delete criterion');
    }
  };

  const handleAdd = async () => {
    if (!newCriterion.trim()) return;

    const newCriteria = [...criteria, newCriterion];
    setCriteria(newCriteria);
    setNewCriterion('');

    try {
      await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ criteria: newCriteria }),
      });
    } catch (err) {
      setError('Failed to add criterion');
    }
  };

  const handleEdit = async (index: number) => {
    if (editingIndex === index) {
      // Save the edit
      const newCriteria = [...criteria];
      newCriteria[index] = editValue;
      setCriteria(newCriteria);
      setEditingIndex(null);

      try {
        await fetch(URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ criteria: newCriteria }),
        });
      } catch (err) {
        setError('Failed to save edited criterion');
      }
    } else {
      // Start editing
      setEditValue(criteria[index]);
      setEditingIndex(index);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">
          Restaurant Criteria Management
        </h2>

        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        <div className="mb-6">
          <div className="flex gap-2">
            <input
              type="text"
              value={newCriterion}
              onChange={(e) => setNewCriterion(e.target.value)}
              placeholder="Enter new criterion"
              className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              onKeyPress={(e) => e.key === 'Enter' && handleAdd()}
            />
            <button
              onClick={handleAdd}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center gap-2"
            >
              <FaPlus className="w-4 h-4" />
              Add
            </button>
          </div>
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="criteria">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {criteria.map((criterion, index) => (
                  <Draggable
                    key={criterion}
                    draggableId={criterion}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg border border-gray-200"
                      >
                        <div
                          {...provided.dragHandleProps}
                          className="text-gray-400 hover:text-gray-600"
                        >
                          <FaGripVertical />
                        </div>
                        <div className="flex-grow">
                          {editingIndex === index ? (
                            <input
                              type="text"
                              value={editValue}
                              onChange={(e) => setEditValue(e.target.value)}
                              onKeyPress={(e) => e.key === 'Enter' && handleEdit(index)}
                              className="w-full p-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                              autoFocus
                            />
                          ) : (
                            <span
                              onClick={() => handleEdit(index)}
                              className="cursor-pointer hover:text-blue-600"
                            >
                              {criterion}
                            </span>
                          )}
                        </div>
                        <button
                          onClick={() => handleDelete(index)}
                          className="text-red-400 hover:text-red-600"
                        >
                          <FaTrash />
                        </button>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default RestaurantCriteria; 
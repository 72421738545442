import React from 'react';
import { Clock, DollarSign, TrendingDown, CircleDollarSign, Wallet } from 'lucide-react';

const MetricsDashboard = () => {
  const formatDate = () => {
    const date = new Date();
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className="p-4 max-w-7xl mx-auto">
      {/* Time & Date Banner */}
      <div className="flex justify-end mb-6">
        <div className="bg-gray-800 text-white px-2 py-1 rounded shadow-sm flex items-center gap-2">
          <Clock className="h-3 w-3" />
          <span className="text-xs font-medium">14:00</span>
          <span className="text-xs text-gray-300 border-l border-gray-600 pl-2">
            {formatDate()}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        {/* Labor Cost % */}
        <div className="bg-gradient-to-br from-red-50 via-red-100 to-red-300 rounded-lg shadow p-6 flex flex-col relative overflow-hidden">
          <div className="absolute right-4 top-4 opacity-10">
            <TrendingDown size={100} strokeWidth={1} />
          </div>
          <div className="mb-3 relative">
            <h3 className="text-base font-semibold flex items-center gap-2 text-gray-700">
              <DollarSign className="h-5 w-5" />
              Labor Cost %
            </h3>
          </div>
          <div className="text-4xl font-bold text-red-600 flex items-center gap-3 relative">
            41.0%
            <span className="text-lg font-semibold text-red-600 bg-white/60 px-2 py-1 rounded">
              ↑ 86%
            </span>
          </div>
          <div className="mt-auto pt-3 border-t border-red-200 relative">
            <div className="flex justify-between items-center text-sm">
              <span className="text-gray-700 font-medium">EOD Projection:</span>
              <span className="font-bold text-lg flex items-center gap-1">
                40%
                <span className="text-red-600 font-semibold">(↑ 82%)</span>
              </span>
            </div>
          </div>
        </div>

        {/* Labor Cost */}
        <div className="bg-gradient-to-br from-amber-50 via-amber-100 to-amber-300 rounded-lg shadow p-6 flex flex-col relative overflow-hidden">
          <div className="absolute right-4 top-4 opacity-10">
            <CircleDollarSign size={100} strokeWidth={1} />
          </div>
          <div className="mb-3 relative">
            <h3 className="text-base font-semibold flex items-center gap-2 text-gray-700">
              <DollarSign className="h-5 w-5" />
              Labor Cost
            </h3>
          </div>
          <div className="text-4xl font-bold text-gray-900 flex items-center gap-3 relative">
            $1,312
            <span className="text-lg font-semibold text-red-600 bg-white/60 px-2 py-1 rounded">
              ↑ 134%
            </span>
          </div>
          <div className="mt-auto pt-3 border-t border-amber-200 relative">
            <div className="flex justify-between items-center">
              <span className="text-gray-700 font-medium">EOD:</span>
              <span className="font-bold text-lg flex items-center gap-1">
                $2,880
                <span className="text-red-600 font-semibold">(↑ 128%)</span>
              </span>
            </div>
          </div>
        </div>

        {/* Total Revenue */}
        <div className="bg-gradient-to-br from-emerald-50 via-emerald-100 to-emerald-300 rounded-lg shadow p-6 flex flex-col relative overflow-hidden">
          <div className="absolute right-4 top-4 opacity-10">
            <Wallet size={100} strokeWidth={1} />
          </div>
          <div className="mb-3 relative">
            <h3 className="text-base font-semibold flex items-center gap-2 text-gray-700">
              <DollarSign className="h-5 w-5" />
              Total Revenue
            </h3>
          </div>
          <div className="text-4xl font-bold text-gray-900 flex items-center gap-3 relative">
            $3,200
            <span className="text-lg font-semibold text-emerald-600 bg-white/60 px-2 py-1 rounded">
              ↑ 14%
            </span>
          </div>
          <div className="mt-auto pt-3 border-t border-emerald-200 relative">
            <div className="flex justify-between items-center">
              <span className="text-gray-700 font-medium">EOD:</span>
              <span className="font-bold text-lg flex items-center gap-1">
                $7,200
                <span className="text-emerald-600 font-semibold">(↑ 16%)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default MetricsDashboard;
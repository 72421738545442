import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './instrument'
import { ErrorBoundary } from "@sentry/react";
import App from './App';
import './index.css';
import ErrorFallback from './components/ErrorFallback';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
  once: true, // Whether animation should happen only once - while scrolling down
  duration: 800, // values from 0 to 3000, with step 50ms
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ErrorBoundary fallback={<ErrorFallback />}>
    <BrowserRouter >
      <App />
    </BrowserRouter >
  </ErrorBoundary>
);
import React, { useState } from 'react';
import GradientBespoke from './GradientBespoke';
import FullPageSpinner from './FullPageSpinner';
import api from '../api';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const handleGoogleLogin = async (): Promise<void> => {
    setLoading(true);
    window.location.href = await api.getGoogleAuthorizationUrl();
  };

  if (loading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-blue-50 to-blue-200">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-12 pb-16 md:pt-16 md:pb-24">
            {/* Section Header */}
            <div className="text-center pb-12 md:pb-16">
              <div className="flex items-center justify-center mb-4">
                <img className="h-12 sm:h-14 w-auto mr-3" src="img/main-logo.png" alt="Bespoke logo" />
                <GradientBespoke />
              </div>
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tighter tracking-tighter mb-4 text-slate-800" data-aos="zoom-y-out">
                Transform Restaurant Management with <span className="bg-clip-text text-transparent bg-gradient-to-r from-slate-700 to-slate-900">AI-Powered Tools</span>
              </h1>
              <div className="max-w-3xl mx-auto">
                <p className="text-xl text-gray-700 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                  Managing a restaurant efficiently shouldn't be overwhelming. Bespoke's AI-powered platform helps you optimize break schedules, streamline employee training, and monitor performance in real-time.
                </p>
              </div>
            </div>

            {/* Hero Image */}
            <div className="relative" data-aos="fade-up" data-aos-delay="450">
              <img className="mx-auto rounded-xl shadow-xl border-2 border-white" src="img/server.png" alt="Hero Image" width="768" height="432" />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Smart Break Management */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-3xl sm:text-4xl font-extrabold text-slate-800 mb-4">
                <i className="mr-2 fas fa-coffee"></i> Smart Break Management
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                Our AI system optimizes break schedules while maintaining service quality and compliance with labor laws. Keep your team happy and your operations running smoothly.
              </p>
              <h3 className="text-xl font-semibold text-blue-700 mb-3">Key Features</h3>
              <ul className="list-disc list-inside text-base text-gray-600 space-y-2">
                <li>Automated Break Scheduling</li>
                <li>Real-time Coverage Analysis</li>
                <li>Labor Law Compliance</li>
                <li>Peak Hour Management</li>
                <li>Break Time Tracking</li>
              </ul>
            </div>

            {/* Training & Performance Monitoring */}
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
              <h2 className="text-3xl sm:text-4xl font-extrabold text-slate-800 mb-4">
                <i className="mr-2 fas fa-chart-line"></i> Training & Performance Monitoring
              </h2>
              <p className="text-lg text-gray-700 mb-6">
                Streamline employee training and enhance service with AI-powered insights. Our system automatically captures customer orders, reducing server stress and improving accuracy while providing performance analytics to help your team grow.
              </p>
              <h3 className="text-xl font-semibold text-blue-700 mb-3">Features</h3>
              <ul className="list-disc list-inside text-base text-gray-600 space-y-2">
                <li>Automated Order Capture</li>
                <li>Order Accuracy Tracking</li>
                <li>Interactive Training Modules</li>
                <li>Performance Analytics</li>
                <li>Service Quality Insights</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Founders Section */}
      <section className="py-16 bg-slate-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-center mb-12 text-slate-800">Meet the Founders</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Eric Chung - CEO */}
            <div className="flex flex-col items-center text-center p-8 bg-white rounded-lg shadow-lg">
              <img
                src="img/eric.png"
                alt="Eric Chung"
                className="w-40 h-40 rounded-full object-cover mb-6 border-4 border-slate-200"
              />
              <h3 className="text-2xl font-bold text-gray-900">Eric Chung</h3>
              <p className="text-blue-700 font-semibold mb-4">CEO & Co-founder</p>
              <p className="text-gray-600 text-lg">
                Former Coinbase executive and restaurant industry veteran. Passionate about revolutionizing restaurant operations through AI-powered solutions.
              </p>
            </div>

            {/* Jesse Hull - CTO */}
            <div className="flex flex-col items-center text-center p-8 bg-white rounded-lg shadow-lg">
              <img
                src="img/jesse.png"
                alt="Jesse Hull"
                className="w-40 h-40 rounded-full object-cover mb-6 border-4 border-slate-200"
              />
              <h3 className="text-2xl font-bold text-gray-900">Jesse Hull</h3>
              <p className="text-blue-700 font-semibold mb-4">CTO & Co-founder</p>
              <p className="text-gray-600 text-lg">
                AI and automation expert with a focus on developing practical solutions for the restaurant industry.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-white mt-10 py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center text-gray-500">
          <a href="/privacy.html" className="hover:text-gray-700 mr-4">Privacy</a>
          <a href="/terms.html" className="hover:text-gray-700">Terms</a>
        </div>
      </footer>
    </div>
  );
};

export default Login;